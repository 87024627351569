import {
  ref, watch, onUnmounted, getCurrentInstance,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { isEnableBed } from '@/auth/utils';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import * as moment from 'moment';
import leadStoreModule from '../leadStoreModule';

export default function useLeadList() {
  const STORE_MODULE_NAME = 'lead';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, leadStoreModule);
  }
  const { t } = useI18nUtils();
  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME);
    }
  });
  // Use toast
  const toastification = toast();

  const blankItem = {
    individual: true,

    avatarFile: null,
    idNumberFrontImageFile: null,
    idNumberBackImageFile: null,
    passportImageFile: null,
    certificateOfBusinessRegistrationImageFile: null,

    avatar: null,
    idNumberFrontImage: null,
    idNumberBackImage: null,
    passportImage: null,
    certificateOfBusinessRegistrationImage: null,

    name: '',
    phone: '',
    email: '',
    birthday: null,
    gender: null,
    idNumber: '',
    issueDate: null,
    placeOfIssue: '',
    job: '',
    workplace: '',
    contactPersonName: '',
    contactPersonPhone: '',

    // address
    province: null,
    district: null,
    ward: null,
    address: '',
    currentAddress: '',
    businessDomain: '',

    // foreign
    foreign: false,
    nationality: null,
    passportNumber: '',
    passportType: '',
    dateOfExpiry: null,

    // company
    businessCode: '',

    note: '',
    vehicles: [],
  };

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const reservationItem = ref({
    id: 0,
    //
    apartment: null,
    room: null,
    bed: null,
    //
    priceForRent: 0,
    deposit: 0,
    totalDeposit: 0,
    depositMonthObject: null,
    depositDate: moment(new Date()),
    checkinDate: moment(new Date()).add(5, 'days'),
    note: '',
    tenant: null,
    sale: null,
    cashbook: null,
    depositTemplate: null,
    refund: 0,
    refundReason: '',
    paymentPeriod: null,
    billingDate: moment(new Date()).add(5, 'days'),
    startDate: moment(new Date()).add(5, 'days'),
    endDate: moment(new Date()).add(5, 'days').add(6, 'months'),
    contractMonth: { title: '6 tháng', value: 6 },
    numberTenants: 1,
    fees: [],
    lead: null,
  });
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  const vm = getCurrentInstance().proxy;
  const isHidden = !vm.$can('update', 'lead') && !vm.$can('delete', 'lead');
  const isVmarket = process.env.VUE_APP_PROVIDER === 'vmarket';

  // Table Handlers
  const columns = [
    {
      label: t('Mã'),
      field: 'code',
      width: '80px',
    },
    {
      label: t('Thao tác'),
      field: 'action',
      tdClass: 'text-center',
      sortable: false,
      width: '100px',
      hidden: isHidden,
    },
    {
      label: t('Mô tả'),
      field: 'name',
    },
    {
      label: t('Trạng thái'),
      field: 'status',
    },

  ];
  const rows = ref([]);
  const analytic = ref(null);
  // filter
  const apartment = ref(null);
  const room = ref(null);
  const bed = ref(null);
  const leadStatus = ref(null);
  const month = ref(null);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: { status: 0 },
    sort: {},
    page: 1,
    perPage: 20,
  });

  //   API Call
  const fetchLeads = () => {
    isLoading.value = true;
    store
      .dispatch('lead/fetchLeads', serverParams.value)
      .then(response => {
        const { total, items } = response.data;
        totalRecords.value = total;
        rows.value = items;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const fetchAnalytics = () => {
    isLoading.value = true;
    store
      .dispatch('lead/fetchAnalytics', serverParams.value)
      .then(response => {
        analytic.value = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const fetchData = () => {
    fetchLeads();
    fetchAnalytics();
  };

  const importData = data => {
    const formData = new FormData();
    formData.append('file', data.file);
    store
      .dispatch('lead/importLeads', formData)
      .then(() => {
        fetchData();
        toastification.showToastCreateSuccess();
        vm.$router
          .push({ name: 'jobs' });
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const exportData = () => {
    store
      .dispatch('lead/exportLeads', serverParams.value)
      .then(response => {
        if (response.data === true) {
          vm.$router.push({ name: 'jobs' });
        } else {
          window.location.href = response.data;
        }
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const deleteLeads = leads => {
    store
      .dispatch('lead/deleteLeads', {
        ids: leads.map(_obj => _obj.id),
      })
      .then(() => {
        fetchData();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  const onEditItem = val => {
    item.value = {
      ...val,
    };
  };

  const onReservation = val => {
    reservationItem.value = {
      id: 0,
      //
      apartment: val.apartment,
      room: null,
      bed: null,
      tenant: val.tenant,
      //
      priceForRent: 0,
      deposit: 0,
      totalDeposit: 0,
      depositMonthObject: null,
      depositDate: moment(new Date()),
      checkinDate: moment(new Date()).add(5, 'days'),
      note: '',
      sale: val.sale,
      cashbook: null,
      depositTemplate: null,
      refund: 0,
      refundReason: '',
      paymentPeriod: null,
      billingDate: moment(new Date()).add(5, 'days'),
      startDate: moment(new Date()).add(5, 'days'),
      endDate: moment(new Date()).add(5, 'days').add(6, 'months'),
      contractMonth: { title: '6 tháng', value: 6 },
      numberTenants: 1,
      fees: [],
      lead: val,
    };
  };

  const onFowardItem = lead => {
    item.value = {
      ...lead,
    };
  };

  const onMarkLeadAsFail = lead => {
    item.value = {
      ...lead,
      isMarkAsFail: true,
    };
  };

  const markLeadFail = lead => {
    vm.$bvModal
      .msgBoxConfirm(
        `Bạn có chắc chắn muốn đánh dấu thất bại trường hợp này?`,
        {
          title: `Tư vấn thất bại`,
          okTitle: `Xác nhận`,
          cancelTitle: `Huỷ`,
          okVariant: 'warning',
          cancelVariant: 'outline-secondary',
        },
      )
      .then(value => {
        if (value) {
          store
            .dispatch('lead/markLeadAsFail', lead.id)
            .then(() => {
              fetchData();
              toastification.showToastSuccess('Đã đánh dấu tư vấn thất bại');
            })
            .catch(error => {
              toastification.showToastError(error);
            });
        }
      });
  };

  //   Watch

  watch(apartment, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.apartmentId = val.id;
    } else {
      delete filter.apartmentId;
    }
    updateParams({ filter });
  });
  watch(room, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.roomId = val.id;
    } else {
      delete filter.roomId;
    }
    updateParams({ filter });
  });
  watch(bed, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.bedId = val.id;
    } else {
      delete filter.bedId;
    }
    updateParams({ filter });
  });
  watch(leadStatus, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.leadStatus = val.value;
    } else {
      delete filter.leadStatus;
    }
    updateParams({ filter });
  });

  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });
  watch(month, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.month = month.value;
    } else {
      delete filter.month;
    }
    updateParams({ filter });
  });

  const resolveColWidthIfDisableBed = isBed => {
    if (isEnableBed()) {
      return 3;
    }
    if (isBed) {
      return 0;
    }
    return 4;
  };

  return {
    analytic,
    item,
    reservationItem,
    columns,
    rows,
    apartment,
    room,
    bed,
    leadStatus,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,
    isVmarket,
    month,
    fetchData,
    fetchLeads,
    deleteLeads,
    resetItem,
    onEditItem,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    importData,
    exportData,
    resolveColWidthIfDisableBed,
    t,
    onFowardItem,
    markLeadFail,
    onReservation,
    onMarkLeadAsFail,
  };
}
