<template>
  <div>
    <b-form-group
      :label="label"
      :state="state"
    >
      <template
        v-if="label"
        v-slot:label
      >
        {{ label }}
        <span
          v-if="required"
          class="text-danger"
        >
          (*)
        </span>
      </template>
      <v-select
        id="room"
        ref="selectMultipleRoom"
        v-model="room"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :disabled="apartment == null || !enable"
        label="name"
        :options="rooms"
        :placeholder="t('Phòng')"
        multiple
        @input="roomChanged"
        @open="onOpen"
      >
        <template
          #list-header
        >
          <li
            class="list-header d-flex align-items-center my-50"
            @click="onSelectAll"
          >
            <feather-icon
              icon="HashIcon"
              size="16"
            />
            <span class="align-middle ml-25">{{ t('Tất cả phòng') }}</span>
          </li>
        </template>
        <!-- eslint-disable-next-line vue/no-unused-vars  -->
        <template #no-options="{ search, searching, loading }">
          {{ t('Không có dữ liệu') }}
        </template></v-select>
      <small class="text-danger">{{ error }}</small>
    </b-form-group>

    <room-modal
      v-if="enableAdd === true"
      :item="{active: true}"
      @refetch-data="fetchRooms"
      @on-item-created="onItemCreated"
    />
  </div>
</template>

<script>
import { BFormGroup, VBModal } from 'bootstrap-vue';
import vSelect from 'vue-select';
import useJwt from '@/auth/jwt/useJwt';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import RoomModal from '../property/room/modal/RoomModal.vue';

export default {
  components: {
    BFormGroup,
    vSelect,
    RoomModal,
    // eslint-disable-next-line vue/no-unused-components
    VBModal,
  },
  directives: {
    "b-modal": VBModal,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: Array,
      default: () => [],
    },
    enableAdd: {
      type: Boolean,
      default: false,
    },
    enable: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    filterActive: {
      type: Boolean,
      default: null,
    },
    enableDefaultSelect: {
      type: Boolean,
      default: false,
    },
    apartment: {
      type: Object,
      default: null,
    },

  },
  data() {
    return {
      room: this.defaultValue,
      rooms: [],
    };
  },
  watch: {
    apartment(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.room = null;
        this.roomChanged();
        if (newVal) {
          this.fetchRooms();
        } else {
          this.rooms = [];
        }
      }
    },
  },
  created() {
    // this.fetchRooms();
  },
  methods: {
    roomChanged() {
      this.$emit('input', this.room);
    },
    onOpen() {
      if (this.rooms.length === 0) {
        this.fetchRooms();
      }
    },
    fetchRooms() {
      if (!this.apartment) {
        return;
      }
      const filter = { apartmentId: this.apartment.id };
      if (this.filterActive) {
        filter.active = this.filterActive;
      }

      useJwt.getRooms({ filter, sort: { field: 'name', type: 'asc' } }).then(res => {
        this.rooms = res.data.items;
      }).catch(() => {
        this.rooms = [];
      });
    },
    onItemCreated(newItem) {
      this.room = newItem;
      this.roomChanged();
    },
    onSelectAll() {
      this.room = this.rooms;
      this.roomChanged();
      this.$refs.selectMultipleRoom.searchEl.blur();
    },
  },
  setup() {
    const { t } = useI18nUtils();
    return { t };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/vue-select.scss';
.list-header {
    padding: $options-padding-y $options-padding-x;
      color: $secondary;

    &:hover {
      background-color: rgba($success, 0.12);
    }
}
</style>
