<template>
  <div>
    <b-form-group
      :state="state"
    >
      <template
        v-if="label !== null"
        v-slot:label
      >
        {{ label }}
        <span
          v-if="required"
          class="text-danger"
        >
          (*)
        </span>
      </template>
      <v-select
        id="leadSource"
        v-model="leadSource"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="title"
        :options="leadSources"
        :placeholder="t('Trạng thái chăm sóc')"
        @input="leadSourceChanged"
        @open="onOpen"
      >
        <!-- eslint-disable-next-line vue/no-unused-vars  -->
        <template #no-options="{ search, searching, loading }">
          {{ t('Không có dữ liệu') }}
        </template></v-select>
      <small class="text-danger">{{ error }}</small>
    </b-form-group>
  </div>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';
import useJwt from '@/auth/jwt/useJwt';
import { useUtils as useI18nUtils } from '@core/libs/i18n';

export default {
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Nguồn',
    },
  },
  data() {
    return {
      leadSource: this.defaultValue,
      leadSources: [],
    };
  },
  setup() {
    const { t } = useI18nUtils();
    return { t };
  },
  created() {
    // this.fetchLeadSources();
  },
  methods: {
    leadSourceChanged() {
      this.$emit('input', this.leadSource);
    },
    fetchLeadSources() {
      useJwt.getLeadStatus().then(res => {
        this.leadSources = res.data.items;
      });
    },
    onOpen() {
      if (this.leadSources.length === 0) {
        this.fetchLeadSources();
      }
    },
    onItemCreated(newItem) {
      this.leadSource = newItem;
      this.leadSourceChanged();
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/vue-select.scss';
</style>
